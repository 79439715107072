<template>
  <vineyard-profile-component @update-title="changeTitle" />
</template>

<script>
import VineyardProfileComponent from "@/components/vineyards/VineyardProfileComponent";

export default {
  name: "VineyardProfile",
  title: "Perfil del Viñedo | Baja California Health Tourism",
  components: { VineyardProfileComponent },
  methods: {
    changeTitle(e) {
      this.title = e;
      document.title = `${e} | Baja California Health Tourism`;
    }
  }
};
</script>

<style scoped></style>
